<template>
  <div v-if="!loading">
    <div>
      <div class="buttons-container">
        <el-link
          :underline="false"
          type="primary"
          class="mr-1"
          @click="resetSettings"
          v-if="checkSettingsChanged()"
          >Reset</el-link
        >
        <el-button type="primary" size="mini" @click="addNewField"
          >New field</el-button
        >
      </div>
      <div>
        <el-scrollbar wrap-style="max-height:70vh;">
          <el-table :data="fields" border style="width: 100%">
            <el-table-column prop="label" label="Label" width="180">
              <template slot-scope="scope">
                <div>
                  <label>
                    {{ scope.row.label }}
                  </label>
                  <el-link
                    icon="el-icon-delete"
                    :underline="false"
                    type="danger"
                    v-if="
                      customization &&
                      customization[scope.row.key] &&
                      customization[scope.row.key]['NEW_FIELD']
                    "
                    @click="removeAddedField(scope.row.key, scope.$index)"
                  ></el-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-for="column in fieldProperties"
              :label="column.label"
              :prop="column.value"
              :key="column.value"
              :width="column.width"
            >
              <template slot-scope="scope">
                <div v-if="customization[scope.row.key]">
                  <div v-if="column.value == 'LABEL'">
                    <el-input
                      v-model="customization[scope.row.key]['LABEL']"
                      placeholder="Value"
                    ></el-input>
                  </div>
                  <div v-else-if="column.value == 'HIDE'">
                    <el-checkbox
                      v-model="customization[scope.row.key]['HIDE']"
                      :disabled="
                        restrictedFields[scope.row.key] ||
                        customization[scope.row.key]['REQUIRED']
                      "
                    >
                    </el-checkbox>
                  </div>
                  <div v-else-if="column.value == 'REQUIRED'">
                    <el-checkbox
                      v-model="customization[scope.row.key]['REQUIRED']"
                      :disabled="
                        restrictedFields[scope.row.key] ||
                        customization[scope.row.key]['HIDE'] ||
                        customization[scope.row.key]['READ_ONLY']
                      "
                    >
                    </el-checkbox>
                  </div>
                  <div v-else-if="column.value == 'READ_ONLY'">
                    <el-checkbox
                      v-model="customization[scope.row.key]['READ_ONLY']"
                      :disabled="
                        restrictedFields[scope.row.key] ||
                        customization[scope.row.key]['REQUIRED']
                      "
                    >
                    </el-checkbox>
                  </div>
                  <div v-else-if="column.value == 'INPUT_TYPE'" class="d-flex">
                    <el-select
                      v-model="customization[scope.row.key]['INPUT_TYPE']"
                      placeholder="Type"
                      :disabled="restrictedFields[scope.row.key]"
                      @change="
                        openSettings(
                          customization[scope.row.key]['INPUT_TYPE'],
                          scope.row
                        )
                      "
                    >
                      <el-option
                        v-for="op in getRelatedTypes(scope.row.key)"
                        :value="op"
                        :label="getFieldLabel(op)"
                        :key="op"
                      >
                      </el-option>
                    </el-select>
                    <el-button
                      plain
                      v-if="
                        customization[scope.row.key]['INPUT_TYPE'] == 'SELECT'
                      "
                      icon="el-icon-setting"
                      @click="openFieldEditing(scope.row)"
                    ></el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
    </div>
    <span>Note: Please update settings whenever template updates</span>
    <dialog-component
      :title="'Field edit'"
      :visible="editingFieldModal"
      @before-close="resetFieldSettings"
      :emitClose="true"
      :width="getIsMobile ? '100%' : '30%'"
    >
      <div
        v-if="
          customization[this.editingFieldKey] &&
          customization[this.editingFieldKey].INPUT_TYPE
        "
        style="padding: 24px"
      >
        <div v-if="customization[this.editingFieldKey].INPUT_TYPE == 'SELECT'">
          <span>Options</span>
          <div>
            <el-tag
              :key="tag + '_' + i"
              v-for="(tag, i) in customization[this.editingFieldKey].OPTIONS"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="mini"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+ New option</el-button
            >
          </div>
        </div>
      </div>
    </dialog-component>
    <dialog-component
      :title="'Add new field edit'"
      :visible="addNewFieldModal"
      @before-close="resetNewField"
      :emitClose="true"
      :width="getIsMobile ? '100%' : '30%'"
    >
      <div class="new-field-modal">
        <div class="field-select">
          <label>Input type</label>
          <el-select
            placeholder="Type select"
            v-model="addNewFieldData.input_type"
          >
            <el-option
              v-for="(op, i) in allowedFieldTypes"
              :key="op + i"
              :value="op"
              :label="getFieldLabel(op)"
            >
            </el-option>
          </el-select>
        </div>
        <template v-if="addNewFieldData && addNewFieldData.input_type">
          <AnyField
            :field="addNewFieldData"
            :fieldsData="fields"
            :selfTemplateId="templateData._id"
          ></AnyField>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="addNewFieldToCustomization"
          :disabled="disableFieldButton(addNewFieldData)"
        >
          <span v-if="editableFieldIndex !== -1">Update Field</span>
          <span v-else>Insert Field</span>
        </el-button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import EntitiesHelper from "../../mixins/EntitiesHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import { makeKey } from "../../helpers/appHelper";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [],
      fieldProperties: [
        {
          value: "LABEL",
          label: "Label",
          width: 180,
        },
        {
          value: "HIDE",
          label: "Hide",
          width: 100,
        },
        {
          value: "REQUIRED",
          label: "Required",
          width: 100,
        },
        {
          value: "READ_ONLY",
          label: "Read only",
          width: 100,
        },
        {
          value: "INPUT_TYPE",
          label: "Type",
          width: 180,
        },
      ],
      fieldChangeObject: [
        ["SINGLE_LINE_TEXT", "MULTI_LINE_TEXT", "SELECT", "NUMBER"],
      ],
      editingFieldModal: false,
      editingFieldKey: "",
      loading: true,
      inputVisible: false,
      inputValue: "",
      restrictedFields: {},
      originalSettings: {},
      addNewFieldModal: false,
      addNewFieldData: {},
      editableFieldIndex: -1,
      allowedFieldTypes: [
        "SINGLE_LINE_TEXT",
        "MULTI_LINE_TEXT",
        "SELECT",
        "NUMBER",
        "MULTI_SELECT",
        "YES_OR_NO",
        "PHONE_COUNTRY_CODE",
        "CURRENCY",
        "CHECKBOX",
        "ENTITY",
        "ENTITY_VARIABLE"
      ],
    };
  },
  components: {
    AnyField: () => import("../templates/formComponents/AnyField.vue"),
  },
  mixins: [EntitiesHelper, TemplateBuilderHelper],
  props: ["templateData", "customization"],
  async mounted() {
    this.loading = true;
    this.fields = [];
    this.fields = this.getTemplateFields(this.templateData);
    this.buildDefaultCustomization();
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
  },
  methods: {
    removeAddedField(key, index) {
      if (this.customization && this.customization[key]) {
        this.fields.splice(index, 1);
        delete this.customization[key];
      }
    },
    generateKeyForField(previousKey, label) {
      if (previousKey) {
        return previousKey;
      }
      return makeKey(label);
    },
    addNewFieldToCustomization() {
      //this.addNewFieldData.inputType = this.addNewFieldData.input_type;
      if (this.editableFieldIndex > -1) {
        this.fields[this.editableFieldIndex] = { ...this.addNewFieldData };
        let obj = this.getFieldCustomizationObj(this.addNewFieldData);
        this.$set(this.customization, this.addNewFieldData.key, {
          ...obj,
          ...{ NEW_FIELD: true, FIELD_DATA: this.addNewFieldData  },
        });
      } else {
        let newKey = this.generateKeyForField(
          this.addNewFieldData.key,
          this.addNewFieldData.label
        );
        let keyCounter = this.fields.filter((e) => e.key == newKey);
        if (keyCounter && keyCounter.length) {
          newKey = newKey + "_" + keyCounter.length;
        }
        this.addNewFieldData.key = newKey;
        this.fields.push({ ...this.addNewFieldData });
        let obj = this.getFieldCustomizationObj(this.addNewFieldData);
        this.$set(this.customization, this.addNewFieldData.key, {
          ...obj,
          ...{ NEW_FIELD: true, FIELD_DATA: this.addNewFieldData },
        });
      }
      this.resetNewField();
    },
    addNewField() {
      this.addNewFieldModal = true;
      this.addNewFieldData = {
        input_type: "",
        inputType: "",
        is_global_variable: false,
        filled_by: "SENDER_OR_RECEIVER",
        validations: {
          required: false,
        },
        key: "",
      };
    },
    resetNewField() {
      this.addNewFieldModal = false;
      this.addNewFieldData = {
        input_type: "",
        inputType: "",
        is_global_variable: false,
        filled_by: "SENDER_OR_RECEIVER",
        validations: {
          required: false,
        },
        key: "",
      };
    },
    openSettings(value, field) {
      if (value == "SELECT") {
        this.openFieldEditing(field);
      }
    },
    checkSettingsChanged() {
      if (
        JSON.stringify(this.originalSettings) !==
        JSON.stringify(this.customization)
      ) {
        return true;
      }
      return false;
    },
    resetSettings() {
      Object.keys(this.originalSettings).forEach((k) => {
        let obj = this.originalSettings[k];
        this.$set(this.customization, k, { ...obj });
      });
      Object.keys(this.customization).forEach((k) => {
        if (!this.originalSettings[k]) {
          delete this.customization[k];
          let index = this.fields.findIndex((e) => e.key == k);
          if (index > -1) {
            this.fields.splice(index, 1);
          }
        }
      });
    },
    handleClose(tag) {
      this.customization[this.editingFieldKey].OPTIONS.splice(
        this.customization[this.editingFieldKey].OPTIONS.indexOf(tag),
        1
      );
    },

    showInput() {
      this.inputVisible = true;
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.customization[this.editingFieldKey].OPTIONS.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    openFieldEditing(field) {
      this.editingFieldKey = field.key;
      this.editingFieldModal = true;
    },
    resetFieldSettings() {
      if (
        this.customization[this.editingFieldKey].INPUT_TYPE == "SELECT" &&
        !this.customization[this.editingFieldKey].OPTIONS.length
      ) {
        this.editingFieldModal = true;
        this.$message({
          message: "Warning, Please add options",
          type: "warning",
        });
      } else {
        this.editingFieldModal = false;
        this.editingFieldKey = "";
      }
    },
    getFieldLabel(string) {
      if (string) {
        string = string.toLowerCase().replaceAll("_", " ");
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return "";
    },
    getRelatedTypes(key) {
      if (key) {
        let field = this.fields.find((e) => e.key == key);
        if (
          this.fieldChangeObject.find((ops) => ops.includes(field.input_type))
        ) {
          return this.fieldChangeObject.find((ops) =>
            ops.includes(field.inputType)
          );
        }
        return [field.input_type];
      }
      return [];
    },
    getFieldCustomizationObj(field) {
      return {
        LABEL: field.label,
        HIDE: field?.properties?.filed_content === "Hide",
        READ_ONLY: field?.properties?.filed_content === "READ ONLY",
        REQUIRED: field?.validations?.required,
        INPUT_TYPE: field.input_type,
        OPTIONS: field.options || [],
        NEW_FIELD: false,
        FIELD_DATA: null
      };
    },
    buildDefaultCustomization() {
      this.fields.forEach((field) => {
        let obj = this.getFieldCustomizationObj(field);
        if (!this.customization[field.key]) {
          this.$set(this.customization, field.key, { ...obj });
        }
        this.$set(this.originalSettings, field.key, { ...obj });
      });
      if (this.templateData?.type == "STANDARD") {
        this.fields.forEach((field) => {
          if (field?.validations?.required) {
            this.restrictedFields[field.key] = true;
            this.restrictedFields[field.key] = true;
            this.restrictedFields[field.key] = true;
          }
        });
      }
      this.fields = [
        ...this.fields,
        ...this.addNewlyAddedFields(this.fields, this.customization),
      ];
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.new-field-modal {
  min-width: 400px;
  min-height: 250px;
}
.field-select {
  margin-top: 20px;
  padding: 25;
  display: flex; /* Use flexbox */
  flex-direction: column;
}
.buttons-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
